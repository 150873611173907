import React from "react";

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 Andrew Browne | Andrew Codes</p>
    </footer>
  );
};

export default Footer;
